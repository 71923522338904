import styled from "styled-components";
interface InputLayoutProps {
  disableBorder?: boolean;
  hidden?: boolean;
  width?: string;
}
export const StyledCheckboxLabel = styled.label`
  margin-left: 5px;
`;

interface CheckboxContainerProps {
  width?: string;
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 2rem 0 2rem;
  width: ${props => props.width ? props.width : "100%"};
  color: gray;
`;

export const StyledFormLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(2px, -50%);
  /* background-color: ${(props) => props.theme.layout.general.bodyTextColor}; */
  padding: 2px 5px;
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
  pointer-events: none;
`;
export const DefaultInput = styled.input`
  position: relative;
  width: ${(props) => (props.type === 'checkbox' ? 'auto' : '100%')};
  padding: 8px 14px;
  outline: none;
  font-size: 1em;
  color: gray;
  // Input sin contenido que no se vea
  /* color: transparent; */
  border-radius: 5px;
  border: solid 1px rgba(0,0,0,.1);
  cursor: ${(props) => (props.type === 'checkbox' ? 'pointer' : 'auto')};

  &::placeholder {
    color: gray;
    // Input sin contenido que no se vea
    /* color: transparent; */
  }

  &:focus ~ ${StyledFormLabel},
  &:not(:placeholder-shown) ~ ${StyledFormLabel},
  &:not(:focus) ~ ${StyledFormLabel} {
    top: 0;
    left: 0;
    transform: translate(2px, -50%);
    background-color: ${(props) => props.theme.layout.general.bodyTextColor};
    padding: 2px 5px;
    font-size: 0.75rem;
  }
`;

export const InputLayout = styled.div<InputLayoutProps>`
  position: relative;
  display: ${(props) => (props.hidden ? "none" : "inherit")};
  margin: 8px 0;
  border-radius: 6px;
  width: 100%;
  label{
    padding-bottom: 1.5rem;
    padding-left: 0;
  }
  z-index: 0;
  @media (min-width: 1024px) {
    width: ${props => props.width ? props.width : 'auto'};
  }
`;

export const StyledFormSelectLabel = styled.label`
  position: absolute;
  transition: all 100ms ease-in-out;
  cursor: text;
  user-select: none;
  border-radius: 4px 4px 0 0;
  top: -13px;
  left: -15px;
  transform: translate(2px, -50%);
  background-color: none;
  padding: 2px 5px;
  font-size: 0.75rem;
`;

export const NormalInput = styled(DefaultInput)`
  border: 1px solid black;
  width: auto;
`;
