import GlobalStyles from "./styled-components/GlobalStyles";
import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Provider } from "react-redux";
import "nprogress/nprogress.css";
import { store } from "./store";
import AppWithStore from "./AppWithStore";
import { useLocation } from "react-router-dom";
import { hideToast } from "./utilities/mensajeUtils";

function App() {
  const location = useLocation();
  const toast = useRef<Toast>(null);
  window.toastRef = toast;

  useEffect(() => {
    hideToast();
  }, [location])

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <GlobalStyles />
      <Provider store={store}>
        <AppWithStore />
      </Provider>
    </>
  );
}

export default App;
