import { SidebarAttributes, SidebarMenu } from "../domain/sidebarNav";
import { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { SidebarContext } from "../../../Sidebar/SidebarContext";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from "@mui/icons-material/Home";

const SidebarNav: FC<SidebarAttributes> = ({ data, isSuccess, initialPath }) => {
  const { verMenuLateral } = useContext(SidebarContext);
  const formatPath = initialPath.toLowerCase().trim() === 'inicio' || initialPath.toLowerCase().trim() === 'resumen' ? 'home' : initialPath;
  const [activePage, setActivePage] = useState<string>(formatPath);
  const [openSubMenu, setOpenSubMenu] = useState<string>('');
  const [openMenu, setOpenMenu] = useState<string>('');

  const handleActivePage = (label: string) => {
    setActivePage(label);
  };

  useEffect(() => {
    if(!verMenuLateral){
      setOpenMenu('');
      setOpenSubMenu('');
    }
  }, [verMenuLateral]);
  
  const handleMenu = (menu: any, typeMenu: string) => {
    if(typeMenu === 'menu'){
      if(openMenu !== menu.menuId){
        setOpenMenu(menu.menuId);
      } else {
        setOpenMenu('');
        setOpenSubMenu('');
      }
    } else if(typeMenu === 'submenu') {
      if(openSubMenu !== menu.menuId){
        setOpenSubMenu(menu.menuId);
      } else {
        setOpenSubMenu('');
      }
    };
  };

  const configureIcon = (item: any) =>{
    const iconMap: any = {
      "/transacciones": <CurrencyExchangeIcon />,
      "/parametria": <QueryStatsIcon />,
      "/administracion": <SettingsIcon />
    };
    return iconMap[item.action]
  }

  const renderMenuItems = (items: SidebarMenu[] | undefined) => {
    return items?.map((item) => (      
        !item.parentMenu ?
        (
          <SubMenu open={item.menuId === openMenu} onClick={() => handleMenu(item, 'menu')} label={item.label} icon={configureIcon(item)}>
            {renderSubMenuItems(item.submenus)}
          </SubMenu>
        ) : null
      )
    )
  }

  const renderSubMenuItems = (submenus: SidebarMenu[] | undefined) => {
    return submenus?.map((subitem) => (
      subitem.isLeaf ? (
        <MenuItem className={activePage.toLowerCase().trim() === subitem.label.toLowerCase().trim() ? 'active-page' : ''} key={subitem.menuId} component={<Link to={subitem.action} /> } onClick={() => handleActivePage(subitem.label)}>
          {subitem.label}
        </MenuItem>
      ) : (
        <SubMenu open={subitem.menuId === openSubMenu} onClick={() => handleMenu(subitem, 'submenu')} label={subitem.label} key={subitem.menuId}>
          {renderSubMenuItems(subitem.submenus)}
        </SubMenu>
      )
    ));
  };
  
  return (
    <StyledSidebarNavMenu>
      <Sidebar className="sidebar-menu" collapsed={verMenuLateral} width="100%">
        <Menu closeOnClick>
          <MenuItem className={activePage.toLowerCase().trim() === "home" ? 'active-page' : ''} icon={<HomeIcon style={{ fontSize: "30px"}} />} key={'HOME_ITEM'} onClick={() => {
            handleActivePage('home');
            setOpenMenu('');
            setOpenSubMenu('');
            }
          } component={<Link to={'/dashboard'} />}>
            Home
          </MenuItem>
          {isSuccess ? renderMenuItems(data) : null }
        </Menu>
      </Sidebar>
    </StyledSidebarNavMenu>
  );
};

export default SidebarNav;

export const StyledSidebarNavMenu = styled.div`
    .sidebar-menu{
      border-right: none !important;
      margin: 15px 10px 15px 0px;
      color: #fff;
      z-index: 10;
      width: 100%;
      transition: width 0s ease-in-out;
      a{
        background-color: #706f6f;
        &:hover{
          background-color: #706f6f;
          color: #c5c5c5;
        }
      }
      .active-page a{
        background-color: #828282 !important;
      }
      .css-wlra1l{
        width: 24%;
      }
    }
`;